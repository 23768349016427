<template>
  <div id="refund" v-if="page_view_show">
    <van-nav-bar title="退款详情" left-arrow @click-left="goLastPage" :border="false" />
    <div class="refund-count">
      <p>师傅5天内未处理,系统将自动退款给下单方</p>
      <p :style="{display: 'flex'}">
        <van-count-down :time="new Date(refund_view_obj.refunds[refund_view_obj.refunds.length - 1].created_at).getTime() + confirm_refund_day * 24 * 60 * 60 * 1000 - new Date().getTime()" format="剩余时间: DD天HH时mm分ss秒" />
      </p>
      <button @click="goSubPage('refund-handle',$route.params.orderId)">马上处理</button>
    </div>
    <div v-for="(item,index) in refund_view_obj.refunds" :key="index">
      <p class="time">
      </p>
      <div class="chatBox">
        <div class="left-avatar flexCenter">
          下单方
        </div>
        <div class="center-content ">
          <p>申请时间: {{item.created_at}}</p>
          <p>申请方式: 全额退额(第{{index + 1}}次)</p>
          <p>申请金额: {{item.refund_fee}} 元</p>
          <p>申请原因: {{item.remark}}</p>
        </div>
        <div class="right-state flexCenter" v-if="index + 1 == refund_view_obj.refunds.length">
          新
        </div>
        <div v-else class="right-state-old">
        </div>
      </div>
    </div>
    <div class="order-info">
      <p class="title">订单信息</p>
      <p><span class="sub-title">订单编号:</span><span class="sub-info">{{refund_view_obj.order.order_id}}</span> </p>
      <p><span class="sub-title">服务类型:</span>{{refund_view_obj.order.service_mode_name}}</p>
      <p><span class="sub-title">申请用户:</span>{{refund_view_obj.order.user_name}}</p>
      <p><span class="sub-title">订单金额:</span>{{refund_view_obj.refunds[refund_view_obj.refunds.length - 1].refund_fee}} 元</p>
      <p><span class="sub-title">退款编号:</span>{{refund_view_obj.refunds[refund_view_obj.refunds.length - 1].refund_id}}</p>
    </div>
  </div>
  <Loading show :type=3 v-else />
</template>
<script>
export default {
  data() {
    return {
      page_view_show: false,
      refund_view_obj: {},
      refund_time: undefined,
    };
  },
  created() {
    this.getRefundaInfo();
  },
  methods: {
    /** 获取退款详情信息
     *
     */
    getRefundaInfo() {
      this.$http
        .get(`/api/v2/worker/order/refund/${this.$route.params.orderId}`)
        .then((res) => {
          console.log("退款详情", res);
          this.refund_view_obj = res.data;
          this.page_view_show = true;
        });
    },
  },
};
</script>

<style lang="less">
#refund {
  padding-bottom: 2rem;
  .van-count-down {
    color: red;
  }
  .refund-count {
    font-size: @theme-font-size-m;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    p {
      padding: 0.5rem 0;
    }
    button {
      border: 1px solid #000;
      background: none;
      padding: 1rem 6.5rem;
      border-radius: 20rem;
      font-size: @theme-font-size-l;
      margin: 0.5rem 0;
    }
  }
  .time {
    font-size: @theme-font-size-m;
    color: #969799;
    padding: 0.6rem 1rem 0 1rem;
  }
  .chatBox {
    display: flex;
    padding: 1rem;
    .left-avatar {
      width: 5rem;
      height: 5rem;
      background: #2fbded;
      border-radius: 0.6rem;
      color: #fff;
      font-size: @theme-font-size-m;
    }
    .center-content {
      flex: 1;
      // min-height: 10rem;
      background: #fff;
      border: 1px solid #f0f0f0;
      margin: 0 1rem;
      font-size: @theme-font-size-m;
      padding: 1rem;
      p {
        padding: 0.3rem 0;
      }
    }
    .right-state {
      width: 2.2rem;
      height: 2.2rem;
      background: #eeeeee;
      color: #b4b3b4;
      border: 1px solid #b4b3b4;
      border-radius: 0.2rem;
      font-size: @theme-font-size-m;
    }
    .right-state-old {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
  .order-info {
    min-height: 10rem;
    background: #fff;
    margin: 1rem;
    border-radius: 0.6rem;
    font-size: @theme-font-size-s;
    padding: 1rem;
    p {
      padding: 0.4rem 0;
      color: #b4b3b4;
    }
    .sub-title {
      padding-right: 1.5rem;
      color: #000;
    }
    .sub-info {
      color: #2fbded;
    }
  }
}
</style>